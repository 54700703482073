.App {
  flex: auto;
  display: flex;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  width: 100%;
}

.Header-logo {
  width:10px;
  resize: both;
  display: flex;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blackbar {
  background-color: black;
  height: 5vh;
  color: white;
  margin-top: 15px;
}
.support-size {
  justify-content: center;
  font-size: 6vw;
  width: 50%;
}

.techpic {
  width: 100%;
  max-width: 560px;
}

.card-header {
  background-color: rgb(216, 25, 25);
  color: white;
}

.align-items-center {
  justify-content: center;
}


.text-center {
  border-style: hidden;
}

