.message-bottom {
    padding-bottom: 5px;
    margin-bottom: 8px;
}

.service-text {
    font-size: 5vw;
    color: rgb(85, 88, 90);
}

.service-dropdown {
    padding-top: 16px;
}

.leftLink {
    color: white; 
    float : left; 
    margin-left : 22.5%;
}

.rightLink {
    color: white; 
    float : right; 
    margin-right : 22.5%;
}

@media screen and (max-width: 610px) {
    .services-top-pic {
        width: 65px;
    }
    
}

@media screen and (max-width: 850px) {
    .leftLink {
        margin-left: 10%;
        font-size: small;
    }
    .rightLink {
        margin-right: 10%;
        font-size: small;
    }
}