.message-top {
    padding-top: 10px;
}

.message-bottom {
    padding-bottom: 5px;
    margin-bottom: 8px;
}

.container-col {
    text-align: left;
    padding: 5vh;
}

.content {
    line-height: 150%;
    font-size: x-large;
    padding: 0 40px;
}

@media screen and (max-width: 400px) {
    .content {
        font-size: inherit;
    }
    
}