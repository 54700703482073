.faq-head {
    padding-top: 20px;
    margin-bottom: 0;
}

.faq-customers {
    padding-bottom: 5px;
    margin-bottom: 8px;
}

.container-col {
    text-align: left;
    padding: 5vh;
}

.faq-accordian {
    max-width: 60vw;
    margin: 0 auto;
}

@media (max-width: 750px) {
    .faq-accordian {
        max-width: 100vw;
    }
    
}
